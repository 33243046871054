<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="snackbar" color="#427D2D" right :timeout="3000">
      <v-layout wrap>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="snackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center align-center fill-height py-16 pt-16>
      <v-flex xs12 sm8 md5 lg4 align-self-center pt-16>
        <v-card rounded="xl" outlined elevation="0">
          <v-layout wrap justify-center>
            <v-flex xs6 py-8>
              <span
                style="
                  font-family: opensansbold;
                  font-size: 25px;
                  color: #427D2D;
                "
                >Forgot password ?
              </span>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center>
            <v-flex xs10 sm10 text-left>
              <v-layout wrap>
                <v-flex text-left>
                  <span style="font-family: opensansregular; font-size: 14px"
                    >Enter your Phone number and we'll send you OTP to reset your
                    password.</span
                  >
                </v-flex>
                <v-flex xs12 pt-3>
                  <span style="font-family: opensansregular; font-size: 13px"
                    >User Name</span
                  >
                  <v-text-field
                    outlined
                    dense class="pt-2"
                    v-model="userName"
                    placeholder="User Name"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <span style="font-family: opensansregular; font-size: 13px"
                    >Phone Number</span
                  >
                  <v-text-field
                    outlined
                    dense class="pt-2"
                    v-model="phone"
                    placeholder="Phone Number"
                  ></v-text-field>
                </v-flex>

               
              </v-layout>
            </v-flex>
            <v-flex xs10 sm10 py-3>
              <v-btn block color="#427D2D" dark @click="sentOTP()">
                <span
                  style="
                    font-size: 14px;
                    font-family: poppinssemibold;
                    text-transform: none;
                  "
                  >Submit</span
                >
              </v-btn>
            </v-flex>
            <v-flex xs10 py-3>
              <span style="font-family: opensanssemibold; font-size: 13px"
                >Back to
              </span>
              <router-link to="/login">
                <span
                  style="
                    font-family: opensanssemibold;
                    font-size: 13px;
                    color: #427D2D;
                  "
                  >Login</span
                >
              </router-link>
            </v-flex>
          </v-layout>
          <v-layout wrap py-5 v-if="status == true">
            <v-flex xs12>
              <span style="font-family: poppinsbold"
                >Please check your email</span
              >
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
// import VieOtpInput from "@bachdgvn/vue-otp-input";
import axios from "axios";
export default {
  components: {
    // VieOtpInput,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      snackbar: false,
      timeout: 5000,
      msg: null,
      msg2: null,
      phone: null,
      userName:"",
      otp: null,
      timeLeft: "00:00",
      intervalTimer: null,
      otpexpired: false,
      
      status: "",
      
    };
  },
  methods: {
    // validateOTP(value) {
    //   this.otp = value;
    // },
    sentOTP() {
      var userData = {};
      userData["phone"] = this.phone;
      userData["userName"] = this.userName;
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/users/resetotp/password/Officials",
        data: userData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.status = response.data.status;
            this.msg = "OTP  Sent Successfully";
            this.$router.push({ path: "/resetPassword", query: { phone: this.phone, userName: this.userName  } });
            
            this.snackbar = true;
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
  <style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>
    